.video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.carousel-indicators li {
    background-color: #DCDDDE;
}

.carousel-indicators .active {
    background-color: #252759;
}

.gallery-slider-img {
    max-height: 450px;
}

#backToTop {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 15px; /* Place the button at the bottom of the page */
    right: 20px; /* Place the button 30px from the right */
    z-index: 200; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #1b4184; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    margin: 0;
    padding: 0;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#backToTop:hover {
    background-color: #252759; /* Add a dark-grey background on hover */
    -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.35);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.35);
}

dl {
    font-size: 17px;
    line-height: 28px
}

/* Overrides */
.page-item.active .page-link {
    background-color: #1b4184;
    border-color: #1b4184;
}

.owl-stage {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.owl-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto !important;
}

.display-4 {
    font-size: 2.3rem;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: 0.5px;
    font-family: Impact, Arial, sans-serif;
}

.carousel-indicators {
    bottom: -50px;
}

body {
    font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #3a3938;
}

/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1 {
    font-weight: 400;
    font-size: 38px;
    line-height: 44px;
    margin: 0 0 5px 0;
    font-family: Impact, Arial, sans-serif;
    letter-spacing: 1px;
    color: #3a3938;
}

h2 {
    font-family: 'Open Sans Condensed', Arial, sans-serif;
    font-weight: 700;
    color: #1b4184;
    margin: 0 0 5px 0;
    font-size: 28px;
}

h3 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
}

h4 {
    font-weight: 700;
    font-size: 22px;
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #3a3938;
}

a {
    color: #3a3938;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover {
    text-decoration: none;
    color: #1b4184;
}

hr {
    margin: 0;
}

.bg-light {
    /*border-bottom: solid 1px #CBCCCE;*/
    background-color: #fff !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.bar-promo {
    min-height: 8px;
    padding: 10px 0;
}

.bar-promo p, .bar-promo h1, .bar-promo h2,.bar-promo h3,.bar-promo h4, .bar-promo h5 {
    margin:0;
    padding:0;
}

.bar-promo p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    margin: 0;
    text-transform: uppercase;
}

.bar-promo a {
    color: #fff;
}

.bar-promo a:hover {
    color: #fff;
    text-decoration: underline;
}

.btn {
    padding: .375rem 1rem;
    font-weight: 600;
    text-transform: uppercase;
    transition:all 0.2s ease-in-out;
}

.btn-primary {
    text-transform: uppercase;
    background-color: #1b4184;
    border-color: #1b4184;
}

.btn-success {
    color: #fff;
    background-color: #63a529;
    border-color: #63a529;
}

.btn-success:hover {
    background-color: #1b873b;
    border-color: #1b873b;
}

.btn-primary:hover {
    background-color: #252759;
    border-color: #252759;
}

.navbar-light .navbar-nav .nav-link {
    color: #3a3938;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #1b4184;
}

.navbar-text {
    font-size: 14px;
    padding-bottom: 0.15rem;
}

.navbar-text .nav-link {
    padding: .5rem 0;
}

.badge {
    font-weight: 600;
}

.badge-primary {
    background-color: #1b4184;
}

.dropdown-menu {
    padding: 0;
}

.dropdown-item {
    padding: .75rem 1.5em;
    border-bottom: solid 1px #CBCCCE;
}

.dropdown-item:last-child {
    border-bottom: none;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #1b4184;
}

.spacer {
    margin: 0 .25rem;
}

.section-padding {
    padding: 4.5em 0;
}

.section-grey {
    background-color: #e9ecef;
}

.section-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#252759+0,1b4184+38,1b4184+50,1b4184+100 */
    background: linear-gradient(to right,  #252759 0%,#1b4184 50%,#1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    color:#ffffff;
}

.section-gradient-ripples {
    background-image: url("/dist/images/DB-Blue-Website-Banner-gradient-ripples.svg");
    background-size: cover;
    overflow-x: hidden;
}

.section-gradient h1, .section-gradient .display-4, .section-gradient h2, .section-gradient h3, .section-gradient h4, .section-gradient h5, .section-gradient h6,.section-gradient p, .section-gradient ul{
    color: #fff;
    opacity:0.9;
}

.section-gradient h2{
    color: #53b606;
}

.overlay-banner{
    top:0;
    width:100%;
    margin-top:-4.5em;
    margin-bottom: 3.5em;
    position:relative;
}

.overlay-banner .notch{
    background-color: #ffffff;
    border-radius:0 0px 50px 50px;
    box-shadow: 0 6px 3px rgba(0, 0, 0, 0.35);
    width:90%;
    margin:0 5%;
    position:relative;
    padding:1.75rem;
}

.overlay-banner .notch:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100px;
    height: 100px;
    width: 100px;
    background: transparent;
    transform: rotate(180deg);

    /* border-radius of pseudo element */
    border-bottom-left-radius: 50px;

    /* box shadow to give the shadow of the pseudo-element the same color as the background */
    box-shadow: 0 50px 0 0 #ffffff;
}

.overlay-banner .notch:after {
    content: "";
    position: absolute;
    top: 0;
    right: -100px;
    height: 100px;
    width: 100px;
    background: transparent;
    transform: rotate(180deg);

    /* border-radius of pseudo element */
    border-bottom-right-radius: 50px;

    /* box shadow to give the shadow of the pseudo-element the same color as the background */
    box-shadow: 0 50px 0 0 #ffffff;
}

.overlay-banner h1, .overlay-banner h2, .overlay-banner h3, .overlay-banner h4, .overlay-banner h5, .overlay-banner h6,.overlay-banner p, .overlay-banner ul, .overlay-banner a{
    color: #46a547;
    font-weight:bold;
    margin:0;
    padding:0;
}

.overlay-banner a{
    text-decoration: underline;
}

.cc-banner .cc-message{
    font-size:14px;
    line-height:26px;
    margin-bottom:10px;
}

.cc-link{
    padding:0 !important;
}

.cc-window.cc-banner{
    flex-direction: column !important;
    align-items: start !important;
}

.space-before-row {
    padding-top: 2em;
}

.column {
    margin-bottom: 3em;
}

.column:last-child {
    margin-bottom: 0;
}

/*.hero-image {
    background-image: url("../images/triangle-green-1.svg"), url("../images/triangle-lblue-2.svg"), url("../images/triangle-blue-2.svg"), url("../images/triangle-grey-2.svg"), url("../images/triangle-green-1.svg");
    background-size: 100px, 100px, 40px, 150px, 35px;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: left -30px top 25px, right -25px top 325px, left 25px top 115px, right 16px top -68px, right 35px top 425px;
}*/

.product-icon {
    margin: 0.5em 0 1.5em 5px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#252759+0,1b4184+38,1b4184+50,1b4184+100 */
    background: linear-gradient(to right,  #252759 0%,#1b4184 50%,#1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: 0px 0px 0px 7px #ffffff, -0px 0px 0px 8px #b4b4b4;
    -moz-box-shadow: 0px 0px 0px 7px #ffffff, -0px 0px 0px 8px #b4b4b4;
    box-shadow: 0px 0px 0px 7px #ffffff, -0px 0px 0px 8px #b4b4b4;
}

.product-icon img {
    width: 100%;
    height: auto;
}

.row-centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
}

/* FEATURED PRODUCT STYLES */

.product-card-text {
    margin: 15px 0;
}

.product-card-text h4 a{
    color: #1b4184;
}

.product-card-text p {
    color: #3a3938;
    font-size: 16px;
    line-height: 24px;
}

/* END FEATURED PRODUCT STYLES */

/* NEWS SECTION */

article.news-block {
    border-bottom: solid 1px #909090;
    padding: 2em 0;
}

article.news-block img {
    float: left;
    width: 125px;
    height: auto;
    border-radius: 4px;
    margin-top: 4px
}

.news-text-wrap {
    margin-left: 150px
}

.spotlight img {
    width: 100% !important;
    height: auto;
    border-radius: 4px;
}

article.spotlight {
    border-bottom: none;
    padding: 2em 0;
}

a.link-to-product {
    text-transform: uppercase;
    font-weight: 700;
    color: #1b4184;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a.link-to-product:hover {
    color: #46a547;
}

/* END NEWS SECTION */

/* TESTIMONIAL SECTION */
.testimonial {
    border: solid 2px #909090;
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding: 35px;
}

.testimonial-holder {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3em;
}

.testimonial img {
    position: absolute;
    height: 60px;
    width: auto;
}

.triangle-1 img {
    top: 0;
    left: 0;
    margin-top: -29px;
    margin-left: 43px;
}

.triangle-2 img {
    bottom: 0;
    right: 0;
    margin-bottom: -29px;
    margin-right: 43px;
}

.triangle-3 img {
    right: 0;
    top: 0;
    margin-top: -29px;
    margin-right: 43px;
}

/* END TESTIMONIAL SECTION */

/* FOOTER STYLES */

footer {
    border-top: solid 1px #909090;
    width: 100%;
}

footer p {
    font-size: 15px;
}

footer ul {
    padding: 0;
}

.footer-upper {
    padding: 45px 0;
}

.footer-lower {
    background-color: #ffffff;
    padding: 20px 0;
}

.footer-text {
    color: #3a3938;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.footer-nav ul li a {
    color: #3a3938;
}

.footer-nav ul li a:hover {
    color: #1b4184;
}

.footer-lower ul li {
    text-transform: uppercase;
    display: inline-block;
    margin: 0 10px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.footer-lower a {
    color: #3a3938;
}

.footer-lower a:hover {
    color: #1b4184;
}

.social-icon {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

svg:hover .social-icon {
    fill: #1b4184;
}

.footer-nav ul li {
    text-transform: none;
    display: block;
    padding: 0;
    margin: 15px 0 0 0;
}

p.column-header {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    color:#1b4184;
}

/* END FOOTER STYLES */

/* FAQ PAGE */

.resources-header {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b3d5f2+0,e7eff7+100 */
    /* background: #b3d5f2; /* Old browsers */
    /* background: -moz-linear-gradient(45deg, #b3d5f2 0%, #e7eff7 100%); /* FF3.6-15 */
    /* background: -webkit-linear-gradient(45deg, #b3d5f2 0%, #e7eff7 100%); /* Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(45deg, #b3d5f2 0%, #e7eff7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3d5f2', endColorstr='#e7eff7', GradientType=1); /* IE6-9 fallback on horizontal gradient */

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#252759+0,1b4184+38,1b4184+50,1b4184+100 */
    background: linear-gradient(to right,  #252759 0%,#1b4184 50%,#1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    color:#ffffff;
}

.resources-header p, .resources-header h1, .resources-header h2, .resources-header h3, .resources-header h4, .resources-header h5, .resources-header h6 {
    color:#ffffff;
}

.resources-header p:last-child{
    margin-bottom: 0;
}

.list-group-item.active {
    background-color: #1b4184;
    border-color: #1b4184;
}

.card-header a {
    display: block;
    width: 100%;
}

/* MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

    .product-icon {
        float: left;
        margin-left: 0;
    }

    span.nudge-left {
        margin-left: 115px;
        display: block;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .column {
        margin-bottom: 0;
    }

    .row-centered {
        flex-direction: row;
    }

    p.footer-text {
        text-align: left;
    }

    ul.footer-text {
        text-align: right;
    }

    .footer-column {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    /*.hero-image {
        background-image: url("../images/triangle-green-1.svg"), url("../images/triangle-lblue-2.svg"), url("../images/triangle-blue-2.svg"), url("../images/triangle-grey-2.svg"), url("../images/triangle-green-1.svg");
        background-size: 200px, 100px, 75px, 250px, 37px;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
        background-position: left -25px bottom -20px, right 100px top -12px, left 75px bottom 175px, right 12px bottom -137px, right 150px top 100px;
    }*/

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .navbar-text .nav-link {
        padding: 0;
    }

    .display-4 {
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 1.15;
    }

    .testimonial-holder {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        margin-bottom: 0;
    }

    .cc-window.cc-banner{
        flex-direction: row !important;
        align-items: center !important;
    }

    .cc-banner .cc-message{
        margin-bottom:0;
    }

    .overlay-banner .notch{
        padding:2.6rem;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /*.hero-image {
        background-image: url("../images/triangle-green-1.svg"), url("../images/triangle-lblue-2.svg"), url("../images/triangle-blue-2.svg"), url("../images/triangle-grey-2.svg"), url("../images/triangle-green-1.svg");
        background-size: 400px, 200px, 150px, 500px, 75px;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
        background-position: left -50px bottom -40px, right 125px top -25px, left 150px bottom 350px, right 25px bottom -275px, right 215px top 165px;
    }*/

}
