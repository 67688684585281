/* DASHBOARD */
.clickable-row {
    cursor: pointer;
}

img.profile-picture {
    width: 86px;
    height: 86px;
    border-radius: 50px;
    border: solid 1px #DCDDDE;
}

ul.sidebar-nav, ul.event-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.sidebar-nav li {
    margin: 0.5em 0;
}

ul.sidebar-nav li a {
    display: block;
    width: 100%;
    padding: 10px 20px;
    border-radius: .25rem;
}

ul.sidebar-nav li a:hover {
    background-color: #e9ecef;
}

ul.sidebar-nav li a.active-page {
    font-weight: bold;
    color: #007AC2;
    background-color: #e9ecef;
}

ul.sidebar-nav li a i {
    margin-right: 10px;
}

ul.event-list li {
    margin-bottom: 1em;
}

ul.event-list li p {
    margin-bottom: 0;
    line-height: 1;
}

ul.event-list li p {
    margin-bottom: 0;
    line-height: 1;
}

ul.event-list li a {
    display: block;
    padding: 1em 1em 1em 1.5em;
    background-color: #e7eff7;
    border-left: solid 6px #007AC2;
    -webkit-transition: all .18s ease-in-out;
    -o-transition: all .18s ease-in-out;
    transition: all .18s ease-in-out;
}

ul.event-list li a:hover {
    color: #444;
    border-left: solid 13px #007AC2;

}

.vertical-middle td {
    vertical-align: middle;
}

img.row-thumbnail {
    height: 45px;
    width: auto;
    margin-right: 15px;
}

.form-block-container {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 2em 0;
}

.form-block {
    border: solid 1px #A7A9AC;
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding: 35px;
}

.form-block:hover {
    border: solid 1px #007AC2;
}

.form-block img {
    height: 70px;
    width: auto;
}

.tool-block {
    border: solid 1px #A7A9AC;
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding: 35px;
}

.input-group-append {
    margin-left: 10px;
}

.input-group-prepend {
    margin-right: 10px;
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

legend {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    display: inline-block;
    margin-bottom: .5rem;
}

form hr {
    margin: 3em 0 2.3em 0;
}

.video-block-container {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 2em 0;
}

.dashboard-content {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

/* SIDEBAR */

#sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    position: fixed;
    padding-top: 15px;
    top: 0;
    left: -250px;
    /* top layer */
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#dismiss {
    position: absolute;
    /* top right corner of the sidebar */
    top: 10px;
    right: 15px;
}

#overlay {
    /* full screen */
    width: 100%;
    height: 100%;
    /* transparent black */
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    /*display: none;*/
    /* middle layer, i.e. appears below the sidebar */
    z-index: 500;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

/* END SIDEBAR */

/* MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

    .form-block-container {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .video-block-container {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .form-block-container {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .video-block-container {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .dashboard-content {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    /* SIDEBAR */
    #sidebar {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        min-width: auto;
        max-width: auto;
        height: auto;
        position: relative;
        padding-top: 0;
        left: 0 !important;
        background-color: none;
        box-shadow: none;
    }

    #dismiss, #opensidebar {
        display: none !important;
    }

    #overlay {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }

    /* END SIDEBAR */

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}


