/* ABOUT PAGE */

/* add to dustbane.css */
.section-padding-top{
	padding: 4.5em 0 0 0;
}

.reason{
	margin:2em 0 1em 0;
}

.section-gradient-vertical p, .section-gradient-vertical h2{
	color: #fff;
}

.section-gradient-vertical h2{
	letter-spacing: 0.03rem;
}

.section-gradient-vertical{
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1f428c+0,007ac2+100 */
background: #252759; /* Old browsers */
background: -moz-linear-gradient(top, #252759 0%, #1b4184 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #252759 0%,#1b4184 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #252759 0%,#1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f428c', endColorstr='#007ac2',GradientType=0 ); /* IE6-9 */
}


.market-icon{
	margin:0 20px 0 0;
	width: 60px;
	height: 60px;
	border-radius:50%;
	-moz-border-radius:50%;
	-webkit-border-radius:50%;
	float:left;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#007ac2+0,1eaedb+100 */
background: #252759; /* Old browsers */
background: -moz-linear-gradient(top, #252759 0%, #1b4184 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #252759 0%,#1b4184 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(top, #252759 0%,#1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007ac2', endColorstr='#1eaedb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

.market-icon:hover{
-webkit-box-shadow:0px 0px 0px 5px #ffffff, 0 6px 10px rgba(0,0,0,0.35);
-moz-box-shadow:0px 0px 0px 5px #ffffff, 0 6px 10px rgba(0,0,0,0.35);
box-shadow:0px 0px 0px 5px #ffffff, 0 6px 10px rgba(0,0,0,0.35);
}

.market-holder{
	margin-top:2em;
}

.market-holder h4{
	margin:0;
}



.value-icon{
	margin:0 0 20px 0;
	width: 100px;
	height: 100px;
	border: solid 3px #444;
	border-radius:50%;
	-moz-border-radius:50%;
	-webkit-border-radius:50%;
}

.nav-pills .nav-link.active{
	background-color: #1b4184;
}

.tab-pane h1{
	margin-bottom:0.5em;
}

.tab-pane img{
	border-radius: 4px;
}

.section-gradient-vertical h1{
	color:#ffffff;
	line-height:1;
}


/* MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

  .market-holder {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;}


}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .market-holder {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;}

}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 


}


