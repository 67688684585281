/* MARKETS */

.market-hero {
    height: 110px;
}

.market-hero-image {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.feature-list-icon {
    float: left;
    width: 50px;
}

.feature-list-text {
    margin-left: 60px
}

.db-way-product {
    float: left;
    width: 100px;
    height: auto;
}

.db-way-text {
    margin-left: 115px;
}

.db-way-text p {
    margin-bottom: 0;
}

.db-way-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 2em 0 1em 0;
}

div.feature-container {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 2em 0 0 0;
}

.tab-pane img {
    border-radius: 4px;
}

.site-survey {
    position: relative;
    overflow: hidden;
}

.site-survey:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.25;
    background-image: url(../images/tip-equipment.svg);
    background-repeat: no-repeat;
    background-position: right -50px bottom -120px;
    background-size: 300px;
}

img.market-sector {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    -webkit-transition: all .19s ease-in-out;
    -o-transition: all .19s ease-in-out;
    transition: all .19s ease-in-out;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

img.market-sector:hover {
    -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 1), 0px 0px 20px 5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 1), 0px 0px 20px 5px rgba(0, 0, 0, 0.6);
    -ms-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 1), 0px 0px 20px 5px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 1), 0px 0px 20px 5px rgba(0, 0, 0, 0.6);
    -webkit-transform: scale(0.92);
    -moz-transform: scale(0.92);
    -ms-transform: scale(0.92);
    transform: scale(0.92);
}

div.market-header-image {
    width: 33.33333%;
}

.market-header-image img {
    width: 100%;
}

.market-sector-holder {
    width: 100%;
    margin: 1em;
}

.market-icon-lg {
    width: 80px;
    height: 80px;
    margin: 0 0 20px 0;
    float: none;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background: #252759; /* Old browsers */
    background: -moz-linear-gradient(top, #252759 0%, #1b4184 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #252759 0%, #1b4184 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #252759 0%, #1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#252759', endColorstr='#1b4184', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    -webkit-box-shadow: 0 0 0 7px #fff, 0 10px 14px rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 0 0 7px #fff, 0 10px 14px rgba(0, 0, 0, .35);
    box-shadow: 0 0 0 7px #fff, 0 10px 14px rgba(0, 0, 0, .35)
}

.market-text-header {
    margin-left: 0;
    display: block
}

.market-slider {
    border-radius: 4px
}

.feature-icon {
    width: 60px;
    height: auto
}

/* MEDIA QUERIES */

@media (min-width: 485px) {

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

    .db-way-product {
        width: 150px;
    }

    .db-way-text {
        margin-left: 170px;
    }

    .market-icon-lg {
        margin: 0;
        float: left
    }

    .market-text-header {
        margin-left: 110px;
        display: block
    }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

    .market-sector-holder {
        width: auto;
        margin: 0 1em;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .market-hero {
        height: 300px;
    }

    .db-way-column {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 3em;
    }

    div.feature-container {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}


