/* SEARCH RESULTS */

ul.search-results-list{
    list-style-type:none;
    margin:0; 
    padding:0;
}

ul.search-results-list h2 a{
    color:#252759;
}

ul.search-results-list h2 a:hover{
    color:#1b4184;
}

ul.search-results-list li{
    margin-bottom:2em;
}

ul.search-results-list li p {
    margin-bottom: 0!important;
}

div.results-column{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

div.product-results-column{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom:3em;
}

.product-listing-item-container-search{
     -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;   
}

.product-listing-item-container-search{
    margin-bottom: 2em;
}

.product-listing-item-container-search img{
    float:none;
}

span.category-badge{
    text-transform:uppercase;
    font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight:600;
    font-size:12px;
    padding:5px 7px;
    vertical-align:bottom;
}

@media (min-width: 485px) { 

.product-listing-item-container-search img{
    width:150px;
    height:150px;
    float:left;
    margin-right:25px;}

.product-listing-item-container-search .product-card-text{
    margin-top:0;}

}

@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    div.results-column{
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;}

    div.product-results-column{
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        margin-bottom:0;}

    .product-listing-item-container-search{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;}

    .product-listing-item-container-search img{
        float:none;
        width: 100%;
        height: auto;}

    .product-listing-item-container-search .product-card-text{
        margin:15px 0;}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
div.results-column{
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;}

div.product-results-column{
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    margin-bottom:0;}

.product-listing-item-container-search{
     -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;}

.product-listing-item-container-search img{
    width:150px;
    height:150px;
    float:left;
    margin-right:25px;}

.product-listing-item-container-search .product-card-text{
    margin-top: 0;}

}
