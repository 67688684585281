/* NETWORK */

div.results-block {
    border: solid 1px #909090;
    height: auto;
    height: 600px;
    overflow: scroll;
}

div.company-block {
    border-bottom: solid 1px #909090;
    padding: 2em 0;
}

div.company-info {
}

div.company-block:first-child {
    padding-top: 0;
}

div.company-block:last-child {
    border-bottom: none;
}

ul.result-icons {
    list-style-type: none;
    margin: 0;
    padding: 0
}

ul.result-icons li {
    display: inline-block;
    margin-right: 3px;
}

.location-view {
    min-height: 600px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.map-view {
    min-height: 600px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

/* MEDIA QUERIES */

@media (min-width: 485px) {

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .location-view {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}


