/* ARTICLES */

article hr {
    margin: 2.5em 0;
}

.article-figure figcaption {
    color: #808285;
    font-size: 15px;
    line-height: 23px;
    margin-top: 0.5em;
    font-style: italic;
}

ul.tag-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.tag-list li {
    display: inline-block;
    background-color: #1b4184;
    border-radius: 0.25rem;
    padding: 0.375rem 1rem;
    font-weight: 600;
    color: #ffffff;
    margin: 5px 5px 5px 0;
}

article.featured-article {
    padding: 1em 0;
}

article.featured-article p {
    margin: 0;
}

article.featured-article img {
    float: left;
    width: 125px;
    height: auto;
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 0;
    display: none;
}

article img {
    border-radius: 4px;
}

.fa-text-wrap {
    margin-left: 0;
}

.callout {
    border: solid 2px #A7A9AC;
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding: 35px;
    margin: 4.5em 0;
}

.callout p {
    margin-bottom: 0 !important;
}

.callout ul {
    margin-bottom: 0 !important;
}

.left-triangle {
    position: absolute;
    height: 70px;
    width: auto;
    margin-top: -72px;
    margin-left: 0;
}

.right-triangle {
    position: absolute;
    height: 50px;
    width: auto;
    right: 0;
    bottom: -60px;
}

.article-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3em;
}

.sidebar-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

/* MEDIA QUERIES */

@media (min-width: 385px) {

    article.featured-article img {
        display: inline;
    }

    .fa-text-wrap {
        margin-left: 150px;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    article.featured-article img {
        float: left;
        width: 125px;
        height: auto;
        margin-top: 4px;
        margin-bottom: 0;
    }

    .fa-text-wrap {
        margin-left: 150px
    }

    .article-column {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        margin-bottom: 0;
    }

    .sidebar-column {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
