/* MANUALS */

ul.file-listing{
	list-style-type: none;
	margin:0;
	padding:0;
}

.file-listing li:before{
    content: "\f15b";  /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
    margin-right: 10px;
}

.file-listing li{
	margin-bottom: 10px;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.file-listing li:hover{
	color:#1b4184;
}

.file-listing li:last-child{
	margin-bottom: 0;
}

.catalogue-column img{
	max-width:250px; 
	margin: 0 auto; 
	height:auto;
}

/* MEDIA QUERIES */

@media (min-width: 485px) { 

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

.catalogue-column{
	border-bottom:none;
	border-right: solid 1px #909090}

.catalogue-column:last-child{
	border-right: none}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}


