.overlay-video {
    display: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    transition: opacity 600ms ease-in;
    transition: opacity 0.6s;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999999;
}

.o1 {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
    transition: opacity 600ms ease-out;
    transition: opacity 0.6s;
}

.videoWrapperExt {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 982px;
    padding: 0 20px;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.close {
    position: absolute;
    top: -50px;
    right: 0px;
    cursor: pointer;
    z-index: 9999;
    color: white;
    transition:all 0.2s ease-in-out;
}

.close:hover{
    color: white;
}

@media (max-width: 767px) and (orientation: landscape) {
    .close {
        display: none;
    }
}
