ul.where-to-use {
    margin: 2rem 0 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

ul.where-to-use li {
    width: calc(33% - 1rem);
    margin-right: 1rem;
}

@media screen and (max-width: 992px) {
    ul.where-to-use li {
        width: calc(50% - 1rem);
    }
}

.where-to-use .card-image-holder {
    width: 100%;
    display: block;
}

.where-to-use .card-image {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    display: block;
    position: relative;
}

.where-to-use .card-image-inner {
    flex: 1 0 auto;
    height: auto;
}

.where-to-use .card-image-inner:before {
    content: '';
    display: block;
    padding-bottom: 70%;
}

.where-to-use .card-image span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    padding: 0;
    overflow: hidden;
}

.where-to-use .card-image img {
    width: 100%;
    height:auto;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    transition: all 0.2s ease-in-out;
}
