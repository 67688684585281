.mobile-search {
    display: inline;
}

.navbar-brand {
    margin-right: 0;
}

.desktop-search {
    display: none;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .mobile-search {
        display: none;
    }

    .navbar-brand {
        margin-right: 1rem;
    }

    .desktop-search {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
    }

}
