/* TRAINING */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section-padding-top {
    padding: 4.5em 0 0 0;
}

.arrow-button {
    text-transform: none !important;
    padding: 1.1rem 1rem;
    white-space: normal;
}

.arrow-button-holder {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.btn-outline-primary {
    color: #1b4184;
    border-color: #1b4184;
}

.btn-outline-primary:hover {
    background-color: #1b4184;
    border-color: #1b4184;
}

div.training-tool-container {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 1em 0;
}

div.training-tool {
    border: solid 1px #909090;
    height: 100%;
}

.training-tool-icon {
    background-color: #1b4184;
    border-radius: 50%;
    width: 54px;
    position: absolute;
    bottom: -27px;
    right: 25px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
}

.training-tool-text {
    color: #909090;
    font-size: 15px;
    line-height: 23px;
}

div.cleaning-tip {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 2em 0 0 0;
}

div.db-way-item, div.db-way-guide {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.db-way-item a {
    display: block;
    width: 100%;
    padding: 0.5em 0.75em;
    margin: 0.5em 0 0 0;
    border-radius: 4px;
}

.db-way-item a:hover {
    background-color: #e9ecef;
}

.db-way-item img {
    float: left;
    margin-right: 9px;
    width: auto;
    height: 25px;
}

.db-way-item p {
    margin-bottom: 0 !important;
    font-weight: 600;
    line-height: 21px;
}

.training-tool p {
    color: #909090;
    font-size: 15px;
    line-height: 23px;
}

.card-inner {
    padding: 25px 25px 60px 25px;
}

.card-link-footer {
    position: absolute;
    bottom: 25px;
    left: 40px;
}

.card-link-footer-list {
    position: relative;
    bottom: 5px;
    right: 25px;
    margin-bottom: 20px;
    text-align: right;
}

.career-sidebar {
    background-color: #e7eff7;
    border-top: solid 5px #1b4184;
}

.training-tool-type {
    background-color: #1b4184;
    border-radius: 50%;
    width: 54px;
    margin-right: 15px;
}

/* MEDIA QUERIES */

@media (min-width: 485px) {

    div.db-way-item, div.db-way-guide {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

    div.training-tool-container {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .arrow-button-holder {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    div.training-tool-container {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    div.cleaning-tip {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    div.db-way-item, div.db-way-guide {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    div.db-way-item, div.db-way-guide {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}


