.nav-pills .product-pills {
    font-weight: 600;
    border: solid 1px #1b4184;
    color: #1b4184;
}

.nav-pills .product-pills:hover {
    opacity: 1;
    color: #fff;
    background-color: #1b4184;
    border-color: #1b4184;
}

.product-staff-pick {
    position: absolute;
    background-color: #63a529;
    padding: 4px 8px !important;
    top: 0px;
    font-size: 12px;
    font-weight: bold !important;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 0 0 5px 0;
    -moz-border-radius: 0 0 5px 0;
    -webkit-border-radius: 0 0 5px 0;
}

.product-gallery-video iframe{
    top: 0;
    left: 0;
    width: 100%;
}

/* EQUIPMENT */
.approvals-popup img {
    width: 75px;
    height: auto;
    float: none;
}

.approvals-popup-text {
    margin-left: 0;
}

.approvals-popup-text p {
    font-size: 14px;
    line-height: 23px
}

.approval-block {
    margin-bottom: 2em;
    clear: both;
    float: none;
}

.approval-block:last-child {
    margin-bottom: 0;
}

div.accessory-block {
    margin-top: 1em;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

/* PRODUCTS */
.card-link-footer-carousel {
    position: absolute;
    bottom: 25px;
    left: 25px;
}

.product-listing-item-container {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.product-listing-item-container-full {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.recommended-product {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.product-listing-item-container, .product-listing-item-container:last-child, .product-listing-item-container-full, .product-listing-item-container-full:last-child {
    margin-bottom: 2em;
}

.product-listing-item {
    border: solid 1px #909090;
    display: block;
    height: 100%;
    box-sizing: border-box;
}

.product-listing-item-container-full img {
    float: none;
    margin-bottom: 0
}

div.toggles {
    padding: 1em 1em 0.5em 1em;
}

.toggle-buttons {
    float: none;
    margin-bottom: 1em;
}

.toggle-buttons a {
    padding: 0.5rem;
}

.toggle-dropdowns {
    float: none;
}

/* ADD TO dustbane.css */
ol.breadcrumb {
    margin: 0 0 2em 0;
    padding: 0;
    background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "\007C";
}

.page-link {
    color: #1b4184;
}

h5 {
    font-weight: 400;
}

/* END ADD TO dustbane.css */

.category-col-bar-holder {
    margin: 1.75em 0;
    height: 5px;
    clear: both;
    float: none;
}

.category-col-bar {
    background-color: #909090;
    height: 5px;
    width: 60px;
    margin-right: 20px;
    display: inline-block;
}

.strippers {
    background-color: #FFE14F;
}

.sealers {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 0px 2px #b4b4b4;
    -moz-box-shadow: 0px 0px 0px 2px #b4b4b4;
    box-shadow: 0px 0px 0px 2px #b4b4b4;
}

.finishes-maintainers {
    background-color: #72CDF4;
}

.ecologo {
    background-color: #94C947;
}

.cleaners {
    background-color: #C3E0A4;
}

.disinfectants {
    background-color: #BED747;
}

.degreasers {
    background-color: #FBB04C;
}

.carpet-care {
    background-color: #EB4498;
}

.hand-cleaners {
    background-color: #FABFB7;
}

.non-acid-cleaners {
    background-color: #B890C2;
}

.acid-cleaners {
    background-color: #F04F30;
}

.specialty-misc {
    background-color: #909090;
}

ul.where-to-use {
    margin: 2rem 0 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

ul.where-to-use li {
    width: calc(33% - 1rem);
    margin-right: 1rem;
}

@media screen and (max-width: 992px) {
    ul.where-to-use li {
        width: calc(50% - 1rem);
    }
}

.card-image-holder {
    width: 100%;
    display: block;
}

.card-image {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    display: block;
    position: relative;
}

.card-image-inner {
    flex: 1 0 auto;
    height: auto;
}

.card-image-inner:before {
    content: '';
    display: block;
    padding-bottom: 70%;
}

.card-image span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    padding: 0;
    overflow: hidden;
}

.card-image img {
    width: 100%;
    height:auto;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    transition: all 0.2s ease-in-out;
}

ul.product-markets, ul.product-approvals, ul.product-gallery, ul.document-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    clear: both;
    float: none;
}

ul.product-markets li img {
    height: 50px;
    width: 50px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#007ac2+0,1eaedb+100 */
    background: #252759; /* Old browsers */
    background: -moz-linear-gradient(top, #252759 0%, #1b4184 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #252759 0%, #1b4184 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #252759 0%, #1b4184 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#252759', endColorstr='#1b4184', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

ul.product-approvals img {
    height: 75px;
    width: auto;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}

ul.product-gallery li{
    position:relative;
    display:inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor:pointer;
    border:solid 2px #909090;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

ul.product-gallery li:hover{
    border:solid 2px #1b4184;
}

ul.product-gallery img{
    width:100px;
    height: 100px;
    padding: 10px;
}

ul.product-gallery .gallery-video:before{
    content: url(../images/gallery-video-icon.svg);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.35;
}

ul.product-gallery a{
    display:block;
}

ul.product-gallery .product-gallery-active {
    border: solid 2px #1b4184 !important;
}

ul.document-list img {
    width: 70px;
    height: 70px;
    float: left;
}

ul.document-list li {
    float: none;
    clear: both;
    margin-bottom: 1.5em;
}

ul.document-list h4, ul.document-list p {
    margin: 0;
    padding: 0;
}

.product-filters-col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.product-listing-col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

#filters-modal, #results-carousel {
    display: block;
}

#filters-sidebar, #results-list {
    display: none;
}

/* MEDIA QUERIES */

@media (min-width: 485px) {
    .approvals-popup img {
        float: left;
    }

    .approvals-popup-text {
        margin-left: 90px;
    }

    .product-listing-item-container {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .product-listing-item img {
        width: 100%;
        margin: 0 0 15px 0;
    }

    .recommended-product {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 2em;
    }

    .product-listing-item-container-full img {
        width: 150px;
        height: 150px;
        float: left;
        margin-right: 25px;
        margin-bottom: 0;
    }

    .product-listing-item-container-full .product-card-text {
        margin-top: 0;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

        #results-carousel {
            display: none;
        }

        #results-list {
            display: block;
        }

        .product-listing-item-container {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }

        .recommended-product {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            margin-bottom: 0;
        }

        .toggle-buttons {
            float: left;
            margin-bottom: 0;
        }

        .toggle-dropdowns {
            float: right;
            margin-top: -8px;
        }

        .product-filters-col {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }

        .product-listing-col {
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }

        #filters-modal {
            display: none;
        }

        #filters-sidebar {
            display: inline;
        }

    }


    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
}
