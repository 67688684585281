/* WARRANTY */
.warranty-category-card-holder{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top:2em !important;
}

.warranty-product-card, .warranty-category-card{
    border: solid 1px #909090;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: block;
    height: 100%
}


.warranty-product-card:hover, .warranty-category-card:hover{
    border: solid 1px #1b4184;
}

.warranty-product-card, .warranty-category-card a{
   display: block;
   padding:25px; 
}

.warranty-product-card img, .warranty-category-card img{
   height:150px;
   width:auto; 
}

.warranty-text-column{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.warranty-section-column{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2em;    
}

/* MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

.warranty-category-card-holder{
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
.warranty-category-card-holder{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.warranty-text-column{
    -ms-flex: 0 0 66.666666%;
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
}

.warranty-section-column{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-bottom:0;
}

}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
.warranty-category-card-holder{
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
}


