/* TEAM PAGE */
.employee-card {
    border: solid 1px #909090;
    display: block;
    box-sizing: border-box;
    text-align: center;
    height: 100%;
}

.employee-card-container {
    margin-bottom:2em;
}

.employee-card-header {
    height: 85px;
}

.leadership {
    background-color: #1b4184;
}

.sales {
    background-color: #909090;
}

.client-services {
    background-color: #63a529;
}

.card-picture {
    height: 125px;
    width: 125px;
    margin-top: -83px;
    margin-bottom: 15px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-box-shadow: 0px 0px 0px 4px #ffffff, 0 6px 10px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 0px 4px #ffffff, 0 6px 10px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 0px 4px #ffffff, 0 6px 10px rgba(0, 0, 0, 0.35);
}

.employee-card p {
    margin: 0;
}

.employee-card a {
    color: #3a3938;
}

.employee-card a:hover {
    color: #1b4184;
}

.team-block {
    margin-bottom: 3em;
}

/* MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .employee-card-container {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .team-block {
        margin-bottom: 1em;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .employee-card-container {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}


