/* FAQ PAGE */
.accordion-open:after {
    font-family: 'FontAwesome';
    content: "\f078";
    float: right;
}

.accordion-closed:after {
    font-family: 'FontAwesome';
    content: "\f054";
    float: right;
}

/* MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}